const config = {
  "broker": "3",
  "name": "French",
  "registrationBundle": "francais",
  "defaultCountryName": "France",
  "defaultCountryCode": "FR",
  "fxgeoip_code": "FR",
  languageCode: 'fr',
  languageName:'French',
  spokenLanguageCode:'fr',
  direction:'ltr',
  footerDisclaimer:'895',
  brand: 'iforex'
};
module.exports = config;